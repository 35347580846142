.screen.dashboard #top-bar {
    background: rgb(6,40,94);
    background: linear-gradient(0deg, rgba(6,40,94,1) 55%, rgba(15,69,156,1) 100%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen.dashboard #top-bar #content {
    max-width: 1920px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 43px;
    padding-bottom: 43px;
    align-items: center;
}

.screen.dashboard #top-bar #titles {
    max-width: 50%;
}

.screen.dashboard #top-bar #titles #title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: left;
    color: #fff;
}

.screen.dashboard #top-bar #titles #sub-title {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #80c0ff;
}

.screen.dashboard #top-bar #titles #sub-title span {
    font-weight: 600;
    color: #fff;
}

.screen.dashboard #top-bar #indicators {
    display: flex;
}

.screen.dashboard #top-bar #indicators #elevated-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid rgba(128, 192, 255, 0.3);
}

.screen.dashboard #top-bar #indicators #elevated-home #progress {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen.dashboard #top-bar #indicators #elevated-home #progress #icon {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-size: 19px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.19px;
    text-align: center;
    color: #fff;
    background-color: #06285e;
}

.screen.dashboard #top-bar #indicators #elevated-home #label {
    margin-top: 14px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.14px;
    text-align: center;
    color: #81d553;
}

.screen.dashboard #top-bar #indicators #other-indicators {
    display: flex;
    flex-direction: row;
}

.screen.dashboard #top-bar #indicators .indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 11px;
}

.screen.dashboard #top-bar #indicators .indicator #icon {
    width: 84px;
    height: 84px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;    
}

.screen.dashboard #top-bar #indicators .indicator.solar #icon {
    background-image: url('../../../assets/imgs/solar-off-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator.solar.on #icon {
    background-image: url('../../../assets/imgs/solar-on-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator.curb #icon {
    background-image: url('../../../assets/imgs/curb-off-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator.curb.on #icon {
    background-image: url('../../../assets/imgs/curb-on-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator.ee #icon {
    background-image: url('../../../assets/imgs/ee-off-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator.ee.on #icon {
    background-image: url('../../../assets/imgs/ee-on-icon.png');
}

.screen.dashboard #top-bar #indicators .indicator #label {
    margin-top: 14px;
    opacity: 0.5;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: -0.14px;
    text-align: center;
    color: rgba(128, 192, 255, 0.5);
}

.screen.dashboard #top-bar #indicators .indicator.on #label {
    color: #fff;
    opacity: 1;
}

.screen.dashboard #widgets {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: center;
    align-items: center;
    background-color: #06285e;
    max-width: 1920px;
}

.screen.dashboard #widgets .widget {
    flex: 1;
    padding: 0;
    border-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
    height: 100%;
    z-index: 1000;
    max-width: 385px;
}

.screen.dashboard #widgets .widget:last-child {
    margin-right: 0px;
}

.screen.dashboard #widgets .widget .body {
    flex: 1;
    padding: 34px 25px 34px 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.screen.dashboard #widgets .widget .body .title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: left;
    color: #0052ca;
}

.screen.dashboard #widgets .widget .body .sub-title {
    margin-top: 22px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
    min-height: 80px;
}

.screen.dashboard #widgets .widget .body .cover-image {
    margin-top: 25px;
    width: 100%;
    height: 264px;
    border-radius: 24px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;    
}

.screen.dashboard #widgets .widget.solar .body .cover-image {
    background-image: url('../../../assets/imgs/energy-consultation.png');
    cursor: pointer;
    background-position: right;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen.dashboard #widgets .widget.solar .body .cover-image .player-button {
    height: 126px;
    width: 126px;
    background-image: url('../../../assets/imgs/play-button.png');
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: cover;    
}

.screen.dashboard #widgets .widget.curb .body .cover-image.off {
    background-image: url('../../../assets/imgs/curb-cover-off.png');
    cursor: pointer;
}

.screen.dashboard #widgets .widget.curb .body .cover-image.on {
    background-image: url('../../../assets/imgs/curb-cover-on.png');
}

.screen.dashboard #widgets .widget.ee .body .cover-image.off {
    background-image: url('../../../assets/imgs/ee-cover-off.png');
    cursor: pointer;
}

.screen.dashboard #widgets .widget .body .cover-image.to-be-installed,
.screen.dashboard #widgets .widget .body .cover-image.reschedule {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: rgb(6,40,94);
    background: linear-gradient(180deg, rgba(6,40,94,1) 0%, rgba(2,68,166,1) 62%);
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #fff;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule {
    flex-direction: column;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule .reschedule-title{
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c1ff;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule .reschedule-subtitle{
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #fff;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule .reschedule-buttons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    justify-content: space-between;
    width: 80%;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule .reschedule-button {
    width: 49%;
    background-color: #81d553;
    padding: 10px;
    border-radius: 5px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #06285e;
    cursor: pointer;
}

.screen.dashboard #widgets .widget .body .cover-image.reschedule .reschedule-button.cancel {
    color: #80c0ff;
    background-color: #06285e;
}

.screen.dashboard #widgets .widget.ee .body .cover-image.on {
    background-image: url('../../../assets/imgs/ee-cover-on.png');
}

.screen.dashboard #widgets .widget.referral-program .body .card {
    width: 100%;
    height: auto;
    padding: 26px 27px 21px;
    border-radius: 24px;
    margin-top: 14px;
    background-color: #81d553;
    display: flex;
    flex-direction: column;
}

.screen.dashboard #widgets .widget.referral-program .body .card .title {
    font-family: Inter;
    font-size: 20px;
    font-weight:lighter ;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: left;
    color: #06285e;
}

.screen.dashboard #widgets .widget.referral-program .body .card .title span {
    font-weight: 600;
}

.screen.dashboard #widgets .widget.referral-program .body .card .body {
    display: flex;
    padding: unset;
    flex-direction: row;
    justify-content: space-between;
}

.screen.dashboard #widgets .widget.referral-program .body .card .body .value {
    font-family: SpaceGrotesk;
    font-size: 50.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: -1.01px;
    text-align: left;
    color: #fff;
}

.screen.dashboard #widgets .widget.referral-program .body .card .body .icon {
    background-image: url('../../../assets/imgs/earned-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 52px;
    height: 48px;
}

.screen.dashboard #widgets .widget.referral-program .body .card.referred {
    background-color: #ede74e;
}

.screen.dashboard #widgets .widget.referral-program .body .card.referred .body .icon {
    background-image: url('../../../assets/imgs/referred-icon.png');
}

.screen.dashboard #widgets .widget.solar .body #step-description {
    flex: 1;
    border-radius: 24px;
    margin-top: 14px;
    overflow: hidden;
    position: relative;
    background-color: #ede74e;
}

.screen.dashboard #widgets .widget.solar .body .gradient {
    background: #06285e;
    background-image: radial-gradient(#fff, #06285e 75%);
    overflow: hidden;
    position: absolute;
    width: 100%;
    height:200px;
}

.screen.dashboard #widgets .widget.solar .body #step-description .bg {
    position: absolute;
    background-color: #ede74e;
    margin-top: 30px;
    border-radius: 50%;
    width: 150%;
    height: 150%;
    margin-left: -25%;
}

.screen.dashboard #widgets .widget.solar .body #step-description .info {
    position: relative;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.screen.dashboard #widgets .widget.solar .body #step-description .title {
    position: relative;
    font-family: Inter;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: -0.22px;
    text-align: center;
    color: #06285e;
    
}

.screen.dashboard #widgets .widget.solar .body #step-description .title .value {
    font-weight:900
}

.screen.dashboard #widgets .widget.solar .body #step-description .progress-bar {
    margin-top: 20px;
    background: rgb(6,40,94);
    background: linear-gradient(0deg, rgba(6,40,94,1) 0%, rgba(15,69,156,1) 50%);
    width: 80%;
    height: 20px;
    border-radius: 10px;
    padding: 3px;
}

.screen.dashboard #widgets .widget.solar .body #step-description .progress-bar .value {
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
}

.screen.dashboard #widgets .widget.solar .body #step-description .name {
    margin-top: 18px;
    font-family: Inter;
    font-size: 22px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: -0.24px;
    text-align: center;
    color: #0052ca;
}

.screen.dashboard #widgets .widget.solar .body #step-description .time-icon .pi  {
    margin-top: 12px;
    color: #06285e;
}

.screen.dashboard #widgets .widget.solar .body #step-description .time-value
{
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.14px;
    text-align: center;
    color: #06285e;
}

.screen.dashboard #widgets .widget .footer {
    height: 50px;
    background-color: #0052cb;
    padding-left: 34px;
    padding-right: 34px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    justify-content: center;
}

.screen.dashboard #widgets .widget .footer .icon {
    width: 40px;
    height: 40px;
    background-image: url('../../../assets/imgs/widget-footer-plus-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
    cursor: pointer;
}

.screen.dashboard #widgets .widget .footer.dark {
    background-color: #06285e;
}

.screen.dashboard #widgets .widget .footer .label
{
    font-family: Inter;
    font-size: 14.5px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #fff;
    cursor: pointer;
}

.screen.dashboard #widgets .widget.add-to-elevated-home {
    background: rgb(6,40,94);
    background: linear-gradient(0deg, rgba(6,40,94,1) 0%, rgba(0,82,203,1) 20%);
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .title {
    color: #ffffff;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .sub-title {
    color: #80c1ff;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .form {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .form .p-inputtext {
    width: 100%;
    height: 100%;
    background-color: #0052cb;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #80c1ff;
    font-family: Inter;
    font-size: 20.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #fff;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .form .contact-me-button {
    margin-top: 24px;
    width: 100%;
    text-align: center;
    background-color: #81d553;
    padding: 20px;
    border-radius: 15px;
    text-transform: uppercase;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    color: #06285e;
    cursor: pointer;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .body .form input::placeholder {
    color: #fff;
    opacity: 0.5;
}

.screen.dashboard #widgets .widget.add-to-elevated-home .footer .icon {
    background-image: url('../../../assets/imgs/widget-footer-play-icon.png');
}

.screen.dashboard #widgets .widget.add-to-elevated-home .footer .label {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
}

.screen.dashboard #rollover-row {
    width: 100%;
    height: 64px;
    background-color: #80c1ff;
    margin-top: -50px;
}

.screen.dashboard #documents {
    background-color: #80c1ff;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
    padding-top: 20px;
}

.screen.dashboard #documents #title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: center;
    color: #06285e;
}

.screen.dashboard #documents #title span {
    margin-right: 10px;
}

.screen.dashboard #documents .list {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1920px;
}

.screen.dashboard #documents .list .document {
    flex: 1;
    display: flex;
    border-right: 1px solid #06285e;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    max-width: 24%;
}

.screen.dashboard #documents .list .document:first-child {
    border-left: 1px solid #06285e;
}

.screen.dashboard #documents .list .document .icon {
    background-image: url('../../../assets/imgs/pdf-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
}

.screen.dashboard #documents .list .document .name {
    margin-left: 20px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
    max-width: 70%;
    overflow-wrap: break-word;
}

.p-dialog {
    width: 40vw
}

.p-dialog-titlebar {
    border: 0;
}

.p-dialog-title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.28px;
    text-align: left;
    color: #06285e;
}

.p-dialog-content {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.survey-options {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.survey-option {
    border-radius: 5px;
    border: 1px solid #06285e;
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.survey-option.selected {
    background-color: #06285e;
    color: #fff;
    font-weight: bold;
}

.survey-comments {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.survey-comments textarea {
    width: 100%;
}

.p-button {
    background-color: #06285e;
    border-color: #06285e;
}