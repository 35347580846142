.header {
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.header .main {
    width: 100%;
    max-width: 1920px;
    padding-left: 2%;
    padding-right: 2%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#back-to-home-referrals{
    cursor: pointer;
    display: flex;
    align-items: center;
}


#back-to-home-referrals i {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: center;
    color: #0052ca;
    margin-right: 10px;
}

#back-to-home-referrals #label {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: center;
    color: #0052ca;
    text-decoration: underline;
}



.header .left .logo {
    background-image: url('../../../assets/imgs/elevation-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 233px;
    height: 37px;
    cursor: pointer;
}

.header .left .title {
    margin-left: 17px;
    font-family: Inter;
    font-size: 28.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.82;
    letter-spacing: -0.31px;
    text-align: left;
    color: #0052ca;
    margin-top: 10px;
}

.header .right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header .right .name {
    margin-right: 19px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.3;
    letter-spacing: -0.3px;
    text-align: right;
    color: #0052ca;
    cursor: pointer;
}

.header .right .profile-icon {
    background-image: url('../../../assets/imgs/avatar.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 43px;
    height: 43px;
    cursor: pointer;
}