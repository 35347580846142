@media screen and (max-width: 1680px) {
    .earnings {
        display: flex;
        flex-direction: column;
    }

    .earnings .card {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        padding: 30px 34px 30px 36px;
        border-radius: 24px;
    }

    .earnings .card.green {
        background-color:#81d553;
    }

    .earnings .card.yellow {
        background-color:#ede74e;
    }

    .earnings .card .card-title.desktop {
        display: flex;
    }

    .earnings .card .card-title.mobile {
        display: none;
    }

    .earnings .card .card-title {
        margin-bottom: 19px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        text-align: left;
        color: #06285e;
    }

    .earnings .card .card-title span {
        font-weight: 600;
    }

    .earnings .card .card-body {
        display: flex;
        justify-content: space-between;
    }

    .earnings .card .card-body .amount {
        font-family: SpaceGrotesk;
        font-size: 40px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -1.23px;
        text-align: left;
        color: #fff;
    }

    .earnings .card .card-body img {
        width: 39px;
        height: 37.5px;
    }

    .earnings .badges-panel {
        display: flex;
        flex-direction: column;
    }

    .earnings .badges-panel .badges-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 10px;
    }

    .earnings .badges-panel .badges-list img {
        width: 48px;
    }

    .earnings .badges-panel .badges-message {
        display: flex;
        justify-content: center;
        margin-bottom: 2px;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        color: #c7c8ca;
        font-style: italic;
    }

    .screen-segment.left.empty-list .earnings {
        display: none;
    }
}