@font-face {
  font-family: 'SpaceGrotesk';
  src: local('SpaceGrotesk'), url(./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-VariableFont_slnt_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'InterSemiBold';
  src: local('Inter'), url(./assets/fonts/Inter-SemiBold.ttf) format('truetype');
}


body {
  background-color: #06285e;
  width: 100vw;
  margin: 0;
}

.App {
  background-color: 06285e;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.screen {
  display: flex;
}

.screen.external {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.screen.external #main {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  max-height: 1080px;
}

.screen.internal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.screen.internal #main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

textarea:focus, input:focus, button:focus{
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

input:enabled:focus, textarea:enabled:focus, select:enabled:focus, button:enabled:focus{
  outline: none;
}

@media screen and (max-width: 1125px) {
  .screen.external {
    height: unset;
  }
}

