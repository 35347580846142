@media screen and (max-width: 1000px) {
    .screen-segment.left.empty-list .earnings {
        display: block;
    }

    .earnings {
        display: flex;
        flex-direction: column;
        padding-top: 25vw
    }

    .earnings .cards-panel {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .earnings .card {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding: 15px;
        border-radius: 12px;
        width: 100%;
        justify-content: space-between;
    }

    .earnings .card.green {
        background-color:#81d553;
    }

    .earnings .card.yellow {
        background-color:#ede74e;
    }

    .earnings .card .card-title.desktop {
        display: none;
    }

    .earnings .card .card-title.mobile {
        display: flex;
    }

    .earnings .card .card-title {
        margin-bottom: 5vw;
        font-family: Inter;
        font-size: 4vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        text-align: left;
        color: #06285e;
    }

    .earnings .card .card-title span {
        font-weight: 600;
    }

    .earnings .card .card-body {
        display: flex;
        justify-content: space-between;
    }

    .earnings .card .card-body .amount {
        font-family: SpaceGrotesk;
        font-size: 8vw;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -1.23px;
        text-align: left;
        color: #fff;
    }

    .earnings .card .card-body img {
        width: 26px;
        height: 25px;
    }

    .earnings .badges-panel {
        display: flex;
        flex-direction: column-reverse;
    }

    .earnings .badges-panel .badges-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 0px;
    }

    .earnings .badges-panel .badges-list img {
        width: 18%;
    }

    .earnings .badges-panel .badges-message {
        display: flex;
        justify-content: center;
        margin-bottom: 1vw;
        font-family: Inter;
        font-size: 4vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        color: #c7c8ca;
        font-style: italic;
    }

    .screen.profile .screen-segment.left .earnings {
        padding-top: 5vw;
    }

    .screen.profile .screen-segment.left .earnings .cards-panel {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }

    .screen.profile .screen-segment.left .earnings .card {
        width: 100%;
        margin-top: 5vw;
        padding: 25px;
    }

    .screen.profile .screen-segment.left .earnings .card .card-title.desktop {
        display: flex;
    }

    .screen.profile .screen-segment.left .earnings .card .card-title.mobile {
        display: none;
    }
}