@media screen and (max-width: 1125px) {
    .screen.dashboard #top-bar {
        background: rgb(6,40,94);
        background: linear-gradient(0deg, rgba(6,40,94,1) 55%, rgba(15,69,156,1) 100%);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .screen.dashboard #top-bar #content {
        max-width: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 5%;
        padding-top: 23px;
        padding-bottom: 43px;
        align-items: center;
    }

    .screen.dashboard #top-bar #titles {
        max-width: unset;
        width: 100%;
    }

    .screen.dashboard #top-bar #titles #title {
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: -0.28px;
        text-align: center;
        color: #fff;
    }

    .screen.dashboard #top-bar #titles #sub-title {
        margin-top: 12px;
        font-family: Inter;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
    }

    .screen.dashboard #top-bar #titles #sub-title span {
        font-weight: 600;
        color: #fff;
    }

    .screen.dashboard #top-bar #indicators {
        display: flex;
        flex-direction: column;
    }

    .screen.dashboard #top-bar #indicators #elevated-home {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: unset;
        margin-right: unset;
        border-right: unset;
        border-bottom: 1px solid rgba(128, 192, 255, 0.3);
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    .screen.dashboard #top-bar #indicators #elevated-home #icon {
        background-image: url('../../../assets/imgs/elevated-home-icon-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 84px;
        height: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Inter;
        font-size: 19px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.19px;
        text-align: center;
        color: #fff;
    }

    .screen.dashboard #top-bar #indicators #elevated-home #label {
        margin-top: 14px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: -0.14px;
        text-align: center;
        color: #81d553;
    }

    .screen.dashboard #top-bar #indicators #other-indicators {
        display: flex;
        flex-direction: row;
        padding-bottom: 50px;
    }

    .screen.dashboard #top-bar #indicators .indicator {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 11px;
    }

    .screen.dashboard #top-bar #indicators .indicator #icon {
        width: 84px;
        height: 84px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;    
    }

    .screen.dashboard #top-bar #indicators .indicator.solar #icon {
        background-image: url('../../../assets/imgs/solar-off-icon.png');
    }

    .screen.dashboard #top-bar #indicators .indicator.curb #icon {
        background-image: url('../../../assets/imgs/curb-off-icon.png');
    }

    .screen.dashboard #top-bar #indicators .indicator.ee #icon {
        background-image: url('../../../assets/imgs/ee-off-icon.png');
    }

    .screen.dashboard #top-bar #indicators .indicator #label {
        margin-top: 14px;
        opacity: 0.5;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: -0.14px;
        text-align: center;
        color: rgba(128, 192, 255, 0.5);
    }

    .screen.dashboard #widgets {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 5%;
        padding-right: 5%;
        justify-content: center;
        align-items: center;
        background-color: #80c1ff;
        max-width: unset;

    }

    .screen.dashboard #widgets .widget {
        flex: 1;
        padding: 0;
        border-radius: 24px;
        box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: unset;
        margin-bottom: 15px;
        height: 100%;
        z-index: 1000;
        max-width: unset;
        width: 100%;
    }

    .screen.dashboard #widgets .widget:first-child {
        margin-top: -60px;
    }

    .screen.dashboard #widgets .widget:last-child {
        margin-right: 0px;
    }

    .screen.dashboard #widgets .widget .body {
        flex: 1;
        padding: 34px 25px 34px 25px;
        display: flex;
        flex-direction: column;
    }

    .screen.dashboard #widgets .widget .body .title {
        font-family: Inter;
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: left;
        color: #0052ca;
    }

    .screen.dashboard #widgets .widget .body .sub-title {
        margin-top: 22px;
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #06285e;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card {
        width: 100%;
        height: auto;
        padding: 26px 27px 21px;
        border-radius: 24px;
        margin-top: 14px;
        background-color: #81d553;
        display: flex;
        flex-direction: column;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card .title {
        font-family: Inter;
        font-size: 20px;
        font-weight:lighter ;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        text-align: left;
        color: #06285e;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card .title span {
        font-weight: 600;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card .body {
        display: flex;
        padding: unset;
        flex-direction: row;
        justify-content: space-between;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card .body .value {
        font-family: SpaceGrotesk;
        font-size: 50.5px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: -1.01px;
        text-align: left;
        color: #fff;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card .body .icon {
        background-image: url('../../../assets/imgs/earned-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 52px;
        height: 48px;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card.referred {
        background-color: #ede74e;
    }

    .screen.dashboard #widgets .widget.referral-program .body .card.referred .body .icon {
        background-image: url('../../../assets/imgs/referred-icon.png');
    }

    .screen.dashboard #widgets .widget.solar .body #step-description {
        flex: 1;
        border-radius: 24px;
        margin-top: 14px;
        overflow: hidden;
        position: relative;
        background-color: #ede74e;
    }

    .screen.dashboard #widgets .widget.solar .body .gradient {
        background: #06285e;
        background-image: radial-gradient(#fff, #06285e 75%);
        overflow: hidden;
        position: absolute;
        width: 100%;
        height:500px;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .bg {
        position: absolute;
        background-color: #ede74e;
        margin-top: 30px;
        border-radius: 50%;
        width: 150%;
        height: 150%;
        margin-left: -25%;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .info {
        position: relative;
        z-index: 1100;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .title {
        position: relative;
        font-family: Inter;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        text-align: center;
        color: #06285e;
        
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .title .value {
        font-weight:900
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .progress-bar {
        margin-top: 20px;
        background: rgb(6,40,94);
        background: linear-gradient(0deg, rgba(6,40,94,1) 0%, rgba(15,69,156,1) 50%);
        width: 80%;
        height: 20px;
        border-radius: 10px;
        padding: 3px;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .progress-bar .value {
        background-color: #fff;
        height: 100%;
        border-radius: 10px;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .name {
        margin-top: 18px;
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: -0.24px;
        text-align: center;
        color: #0052ca;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .time-icon .pi  {
        margin-top: 12px;
        color: #06285e;
    }

    .screen.dashboard #widgets .widget.solar .body #step-description .time-value
    {
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.14px;
        text-align: center;
        color: #06285e;
    }

    .screen.dashboard #widgets .widget .footer {
        height: 50px;
        background-color: #0052cb;
        padding-left: 34px;
        padding-right: 34px;
        display: flex;
        align-items: center;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        justify-content: center;
    }

    .screen.dashboard #widgets .widget .footer .icon {
        width: 30px;
        height: 30px;
    }

    .screen.dashboard #widgets .widget .footer.dark {
        background-color: #06285e;
    }

    .screen.dashboard #widgets .widget .footer .label
    {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #fff;
        cursor: pointer;
    }

    .screen.dashboard #rollover-row {
        display: none;
    }

    .screen.dashboard #documents {
        background-color: #80c1ff;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        align-items: center;
        padding-top: 40px;
    }

    .screen.dashboard #documents #title {
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: -0.28px;
        text-align: center;
        color: #06285e;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 8%;
        padding-right: 8%;
    }

    .screen.dashboard #documents #title span {
        margin-right: unset;
    }

    .screen.dashboard #documents .list {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: unset;
    }

    .screen.dashboard #documents .list .document {
        flex: 1;
        display: flex;
        border-right: unset;
        border-bottom: 1px solid #06285e;
        padding-left: 8%;
        padding-right: 8%;
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: row;
        max-width: unset;
        width: 100%;
    }

    .screen.dashboard #documents .list .document:first-child {
        border-left: unset;
    }

    .screen.dashboard #documents .list .document .icon {
        background-image: url('../../../assets/imgs/pdf-icon.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
    }

    .screen.dashboard #documents .list .document .name {
        margin-left: 20px;
        font-family: Inter;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #06285e;
        max-width: 70%;
        overflow-wrap: break-word;
    }

    .p-dialog {
        width: 90vw;
    }

    .survey-option {
        width: 25px;
        height: 25px;
    }
}