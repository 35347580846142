@media screen and (max-width: 1125px) {
    .footer {
        padding-top: 30px;
        background-color: #06285e;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 30px;
    }

    .footer .links-container {
        display: flex;
        flex-direction: column;
    }

    .footer .links {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
        margin-bottom: 10px;
    }

    .footer .links .link {
        cursor: pointer;
    }

    .footer .links .divider {
        margin-left: 10px;
        margin-right: 10px;
    }

    .footer .links:first-child .divider:last-child {
        display: none;
    }

    .footer .review {
        background-image: url('../../../assets/imgs/write-review-bg.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 90%;
        height: 90px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    .footer .review .title {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
    }

    .footer .review .sub-title {
        margin-top: 11px;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: center;
        color: #80c0ff;
    }

    .footer .review .sub-title {
        font-weight: 600;
    }
}