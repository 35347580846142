@media screen and (max-width: 1000px) {
.tabs-body {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.tabs-header {
    display: flex;
    flex-direction: row;
    height: 73px;
    background-color: #fff;
}

.tab-header {
    flex: 1;
    border: 2px solid #80c0ff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #80c0ff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Inter;
    font-size: 3vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.24px;
    cursor: pointer;
}


.tab-header.selected {
    border-bottom: none;
    color: #0052ca;
    cursor: default;
}

.tabs-content {
    background-color: #fff;
    flex: 1;
    border: none;
    color: #000;
    padding: 10px;
}
}