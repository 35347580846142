@media screen and (max-width: 1125px) {

    .header {
        background-color: #fff;
        width: 100%;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;
        padding-bottom: 10px;
        box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.75);
        z-index: 1000;
    }

    .header .main {
        width: 100%;
        max-width: 1920px;
        padding-left: 5%;
        padding-right: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header .left {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .header .left .logo {
        background-image: url('../../../assets/imgs/elevation-logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 133px;
        height: 37px;
    }

    .header .left .title {
        margin-left: 17px;
        font-family: Inter;
        font-size: 18.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: unset;
        letter-spacing: -0.31px;
        text-align: left;
        color: #0052ca;
        margin-top: 0px;
    }

    #back-to-home-referrals i {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: -0.28px;
        text-align: center;
        color: #0052ca;
        margin-right: 0px;
    }
    
    #back-to-home-referrals #label {
        display: none;
    }

    .header .right .name {
        display: none;
    }
    
    .header .right .profile-icon {
        width: 33px;
        height: 33px;
    }

}