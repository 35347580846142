.screen.tracker {
    padding-bottom: 85px;
}

#back-to-home {
    position: absolute;
    top: 0px;
    right: 59px;
    margin-top: 61px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

#back-to-home i {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: center;
    color: #0052ca;
    margin-right: 10px;
}

#back-to-home #label {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: center;
    color: #0052ca;
    text-decoration: underline;
}


.screen.tracker #tracker-bar {
    position: relative;
    background-color: #ede74e;
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url('../../../assets/imgs/tracker-bg.png');
    background-size: 40%;
    background-repeat: no-repeat;
    background-position: top right;
    padding-bottom: 80px;
}

.screen.tracker #tracker-bar #content {
    max-width: 1920px;
    width: 100%;
    padding-left: 59px;
    padding-right: 59px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.screen.tracker #tracker-bar #content #title {
    margin-top: 61px;
    font-family: Inter;
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.71;
    letter-spacing: -0.28px;
    text-align: center;
    color: #0052ca;
}

.screen.tracker #tracker-bar #content #sub-title {
    margin: 35px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #06285e;
    max-width: 620px;
}

.screen.tracker #tracker-bar #content #steps {
    max-width: 1920px;
    width: 100%;
    display: flex;   
    justify-content: space-between;
    z-index: 2;
}

.screen.tracker #tracker-bar #content #steps .step {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 121px;
    flex: 3;
}

.screen.tracker #tracker-bar #content #steps .step .label {
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.36px;
    text-align: center;
    color: #a09b1f;
    width: 100px;  
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}

.screen.tracker #tracker-bar #content #steps .step .label.complete {
    color: #0052ca;
    font-weight: 600;
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar {
    box-shadow: 0px 9px 24.8px 2.2px rgba(0, 82, 202, 0.35);
    background-color: #0052ca;
    width: 100%;
    height: 53px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar .inner-bar {
    background-color: #80c1ff;
    opacity: 0.6;
    height: 16px;
    width: 100%;
    box-shadow: none;
    z-index: 110;
}

.screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child {
    background: #0052ca;
    background: linear-gradient(90deg, #0052ca 0%, #80c1ff 100%);
}

.screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child.complete {
    background: #0052ca;
    background: linear-gradient(90deg, #0052ca 0%, #ede74e 100%);
}

.screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child {
    background: #0052ca;
    background: linear-gradient(270deg, #0052ca 0%, #80c1ff 100%);
}

.screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child.complete {
    background: #0052ca;
    background: linear-gradient(270deg, #0052ca 0%, #ede74e 100%);
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar .inner-bar.complete {
    background-color: #ede74e;
    opacity: 1;
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator {
    position: absolute;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: #80c1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 120;
    box-shadow: 0 0 9.2px 0.8px #0052ca;
    cursor: pointer;
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator.complete {
    background-color: #ede74e;
}

.screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator .inner-indicator {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #0052ca;
}



.screen.tracker #tracker-bar #content #steps .step:first-child {
    flex: 2;
    /* align-items: flex-start; */
}

.screen.tracker #tracker-bar #content #steps .step:first-child .label {
    /* margin-left: 25px; */
}

.screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar {
    border-top-left-radius: 26px;
    border-bottom-left-radius: 26px;
    /* justify-content: flex-start;
    padding-left: 68px; */
}

.screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:first-child {
    display: none;
}

.screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child {
    width: 50%;
    margin-left: 50%;
}

.screen.tracker #tracker-bar #content #steps .step:last-child {
    flex: 2;
    /* align-items: flex-end; */
}

.screen.tracker #tracker-bar #content #steps .step:last-child .label {
    /* margin-right: 25px; */
}

.screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar {
    border-top-right-radius: 26px;
    border-bottom-right-radius: 26px;
}

.screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child {
    width: 50%;
    margin-right: 50%;
}

.screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:last-child {
    display: none;
}

.screen.tracker #tracker-bar #content #steps .step .arrow.current
{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 13px 0 13px;
    border-color: #0051ca transparent transparent transparent;
    z-index: 100;
}

.screen.tracker #footer-content {
    width: 100%;
    max-width: 1920px;
    padding-left: 120px;
    padding-right: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: center;
    z-index: 1;
}

.screen.tracker #step-title-bar {
    display: flex;
    flex-direction: row;
    height: 96px;
    background-color: #DEEEFF;
    width: 100%;
    margin-top: -96px;
    box-shadow: 0px 0px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    padding-left: 55px;
    align-items: center;
    padding-top: 16px;
}

.screen.tracker #step-title-bar #title {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.63;
    letter-spacing: -0.32px;
    text-align: left;
    color: #0052ca;
}

.screen.tracker #step-title-bar #duration {
    display: flex;
    align-items: center;
}

.screen.tracker #step-title-bar #icon {
    width: 25px;
    height: 25px;
    margin-left: 21px;
    background-image: url('../../../assets/imgs/clock-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.screen.tracker #step-title-bar #timeframe {
    margin-left: 7px;
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.14px;
    text-align: right;
    color: #06285e;
}

.screen.tracker #step-content-bar {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: 0px 14px 27.6px 2.4px rgba(0, 56, 124, 0.4);
    background-color: #fff;
    width: 100%;
    padding: 43px 55px;
    display: flex;
    flex-direction: row;
    z-index: 105;
}

.screen.tracker #step-content-bar #description {
    flex: 2;
    padding-right: 47px;
    border-right: 1px solid #8ab9e5 ;
}

.screen.tracker #step-content-bar #description .title {
    font-family: Inter;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    text-align: left;
    color: #0052ca;
    margin-bottom: 24px;
}

.screen.tracker #step-content-bar #description .text {
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.16px;
    text-align: left;
    color: #6d6e71;
    margin-bottom: 24px;
}

.screen.tracker #step-content-bar #video-content {
    flex: 1;
    padding-left: 47px;
}

.screen.tracker #step-content-bar #video-content #thumbnail {
    width: 434px;
    height: 244.5px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.screen.tracker #step-content-bar #video-content #thumbnail .player-button {
    height: 126px;
    width: 126px;
    background-image: url('../../../assets/imgs/play-button.png');
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: cover;    
}

.screen.tracker #step-content-bar #video-content #text {
    margin-top: 23px;
    font-family: Inter;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #0052ca;
}

.screen.tracker #step-content-bar #video-content #title {
    margin-top: 23px;
    margin-bottom: 35px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #0052ca;
    text-transform: uppercase;
}

.screen.tracker #next-step-bar {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    background: rgb(78,215,45);
    background: linear-gradient(90deg, rgba(78,215,45,1) 0%, rgba(128,193,255,1) 600px);
    width: 100%;
    margin-top: -24px;
    padding: 53px 55px 29px 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.screen.tracker #next-step-bar #icon {
    width: 70px;
    height: 70px;
    background-image: url('../../../assets/imgs/what-is-next.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.screen.tracker #next-step-bar #text {
    margin-left: 27px;
}

.screen.tracker #next-step-bar #text span:first-child {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #fff;
}

.screen.tracker #next-step-bar #text span,
.screen.tracker #next-step-bar #text span:last-child {
    margin-left: 5px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: left;
    color: #06285e;
}

.video-dialog {
    width: 100%;
    max-width: 50vw;
}

.video-dialog .p-dialog-title {
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.63;
    letter-spacing: -0.32px;
    text-align: left;
    color: #0052ca;
}

.video-dialog .content {
    position: relative;    
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-dialog .content .player-button {
    height: 126px;
    width: 126px;
    background-image: url('../../../assets/imgs/play-button.png');
    background-repeat: no-repeat;
    cursor: pointer;
    background-size: cover;
    position: absolute;
    z-index: 1000;
}