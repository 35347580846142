@media screen and (max-width: 1680px) {
    .screen.home {
        align-items: flex-start;
    }

    .screen.home .screen-segment {
        background-color: #fff;
    }

    .screen.home .screen-segment.left {
        display: flex;
        flex-direction: column;
        align-self: stretch;
    }

    .screen.home .screen-segment.left.empty-list {
        display: flex;
        justify-content: center;
    } 

    .screen.home .screen-segment.left.empty-list .title {
        font-family: Inter;
        font-size: 44px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.96px;
        text-align: left;
        color: #06285e;
        text-transform: none;
    }

    .screen.home .screen-segment.left.empty-list .subtitle {
        font-family: Inter;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.26px;
        text-align: left;
        color: #0052ca;
    }

    .screen.home .screen-segment.left.empty-list .subtitle span {
        font-weight: 600;
    }

    .screen.home .screen-segment.left.empty-list .buttons .button {
        width: 258px;
        height: 50px;
        font-family: SpaceGrotesk;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }


    .screen.home .screen-segment.left .title {
        font-family: Inter;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.36px;
        text-align: left;
        color: #06285e;
        text-transform: uppercase;
    }

    .screen.home .screen-segment.left .subtitle {
        margin-top: 10px;
        font-family: Inter;
        font-size: 17px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: -0.22px;
        text-align: left;
        color: #0052ca;
    }

    .screen.home .screen-segment.left .referral-list {
        margin-top: 20px;
        width: 100%;
        display: flex;
        
    }

    .screen.home .screen-segment.left .referral-list .column-header{
        text-transform: uppercase;
        font-family: Inter;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.18px;
        text-align: left;
        color: #c7c8ca;
        display: flex;
        align-items: center;
    }

    .screen.home .screen-segment.left .referral-list .column-header.center{
        text-align: center;
        justify-content: center;
    }

    .screen.home .screen-segment.left .referral-list-item {
        margin-top: 16px;
        width: 100%;
        display: flex;
        height: auto;
        flex-direction: column;
        border-bottom: 1px solid #c7c8ca;
        padding-bottom: 10px;
    }

    .screen.home .screen-segment.left .referral-list-item .columns-row {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row;
    }

    .screen.home .screen-segment.left .referral-list-item .columns-row .column-value{
        font-family: Inter;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.18px;
        text-align: left;
        color: #0052ca;
    }

    .screen.home .screen-segment.left .referral-list-item .column-value.center{
        text-align: center;
    }

    .screen.home .screen-segment.left .referral-list-item .column-value.grey{
        color: #939598;
    }

    .screen.home .screen-segment.left .referral-list-item .payments-row {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        align-content: center;
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: -0.18px;
        text-align: left;
        color: #939598;
    }

    .screen.home .screen-segment.left .referral-list-item .payments-row .payment-column  {
        display: flex;
        flex-direction: row;
        margin-right: 34px;
        align-items: center;
    }

    .screen.home .screen-segment.left .referral-list-item .payments-row .payment-column img {
        width: 20px;
        height: 20px;
        margin-right: 0px;
    }

    .screen.home .screen-segment.left .referral-list-item .payments-row .payment-column input {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        margin-top: 0px;
    }

    .screen.home .screen-segment.left .buttons .button {
        font-size: 15spx;
        height: 57px;
    }

    .screen.home .screen-segment.right {
        width: 45%;
        align-self: stretch;
        margin-left: 20px;
    }

    .screen.home .pagination {
        font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: -0.18px;
    text-align: left;
    color: #939598;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
        margin-top: 20px;
    }

    .screen.home .pagination .page-size {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 20px;
    }

    .screen.home .pagination .page-size .label {
        padding-right: 10px;
        height: 44px;
        vertical-align: middle;
        padding-top: 10px;
        text-align: right;
    }

    .screen.home .pagination .page-size .p-dropdown {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #939598;
        color: #939598;
        padding-left: 0px;
    }

    .screen.home .pagination .page-size .p-dropdown-label {
        color: #939598;
    }

    .screen.home .pagination .page-list {
        display: flex;
        flex-direction: row;
    }

    .screen.home .pagination .page {
        padding: 5px;
        cursor: pointer;
    }

    .screen.home .pagination .page.current {
        font-weight: bold;
        cursor: default;
    }

    .screen.home .pagination .divider {
        padding: 5px;
    }

    .new-referral-dialog-title {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.36px;
        text-align: left;
        color: #06285e;
        text-transform: uppercase;
    }

    .new-referral-dialog-form input, .new-referral-dialog-form input:focus, .new-referral-dialog-form input:hover {
        border: 0;
        border-bottom: 1px solid #06285e;
        font-family: Inter;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.22px;
        text-align: left;
        color: #06285e;
        border-radius: 0;
        width: 100%;
        outline: none;
        margin-bottom: 10px;
    }

    .new-referral-dialog-form .referral-error {
        font-size:15px;
    }

    .new-referral-options-buttons {
        margin-top: 20px;
    }

    .new-referral-buttons .button, .new-referral-buttons .button:focus, .new-referral-buttons .button:hover {
        font-family: SpaceGrotesk;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 3;
        letter-spacing: normal;
        text-align: left;
        color: #0052ca;
        background-color: white;
        border: none;
        text-transform: uppercase;
    }

    .screen.home .screen-segment.left .referral-list.desktop {
        display: flex;
    }

    .screen.home .screen-segment.left .referral-list-item.desktop {
        display: flex;
    }

    .screen.home .screen-segment.left .referral-list-item.mobile {
        display: none;
    }

    .screen.home .screen-segment.left .earnings {
        display: none;
    }
}