@media screen and (max-width: 1125px) {

    .screen.tracker {
        padding-bottom: 50px;
    }
    
    #back-to-home {
        position: absolute;
        top: 0px;
        right: 0px;
        margin-top: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    #back-to-home i {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: -0.28px;
        text-align: center;
        color: #0052ca;
        margin-right: 10px;
    }
    
    #back-to-home #label {
        display: none;
    }

    .screen.tracker #tracker-bar {
        background-color: #ede74e;
        width: 100%;
        display: flex;
        justify-content: center;
        background-image: unset;
        background-size: 40%;
        padding-bottom: 0px;
    }
    
    .screen.tracker #tracker-bar #content {
        max-width: unset;
        width: 100%;
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .screen.tracker #tracker-bar #content #title {
        margin-top: 40px;
        padding-left: 5%;
        padding-right: 5%;
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: -0.28px;
        text-align: left;
        color: #0052ca;
    }
    
    .screen.tracker #tracker-bar #content #sub-title {
        margin: unset;
        margin-top: 20px;
        padding-left: 5%;
        padding-right: 5%;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #06285e;
        max-width: 620px;
    }
    
    .screen.tracker #tracker-bar #content #steps {
        max-width: unset;
        width: 100%;
        display: flex;   
        justify-content: space-between;
        margin-top: 30px;
    }
    
    .screen.tracker #tracker-bar #content #steps .step {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: unset;
        flex: 3;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .label {
        display: none;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .label.complete {
        color: #0052ca;
        font-weight: 600;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar {
        box-shadow: 0px 9px 24.8px 2.2px rgba(0, 82, 202, 0.35);
        background-color: #0052ca;
        width: 100%;
        height: 53px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar .inner-bar {
        background-color: #80c1ff;
        opacity: 0.6;
        height: 16px;
        width: 100%;
        box-shadow: none;
        z-index: 110;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child {
        background: #0052ca;
        background: linear-gradient(90deg, #0052ca 0%, #80c1ff 100%);
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child.complete {
        background: #0052ca;
        background: linear-gradient(90deg, #0052ca 0%, #ede74e 100%);
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child {
        background: #0052ca;
        background: linear-gradient(270deg, #0052ca 0%, #80c1ff 100%);
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child.complete {
        background: #0052ca;
        background: linear-gradient(270deg, #0052ca 0%, #ede74e 100%);
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar .inner-bar.complete {
        background-color: #ede74e;
        opacity: 1;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        background-color: #80c1ff;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 120;
        box-shadow: 0 0 9.2px 0.8px #0052ca;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator.complete {
        background-color: #ede74e;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .outter-bar .indicator .inner-indicator {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #0052ca;
    }
    
    
    
    .screen.tracker #tracker-bar #content #steps .step:first-child {
        flex: 2;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .label {
        
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;
        
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:first-child {
        display: none;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:first-child .outter-bar .inner-bar:last-child {
        width: 50%;
        margin-left: 50%;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child {
        flex: 2;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .label {
        
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar {
        border-top-right-radius: unset;
        border-bottom-right-radius: unset;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:first-child {
        width: 50%;
        margin-right: 50%;
    }
    
    .screen.tracker #tracker-bar #content #steps .step:last-child .outter-bar .inner-bar:last-child {
        display: none;
    }
    
    .screen.tracker #tracker-bar #content #steps .step .arrow.current
    {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 10px 0 10px;
        border-color: #0051ca transparent transparent transparent;
        z-index: 100;
    }
    
    .screen.tracker #footer-content {
        width: 100%;
        max-width: unset;
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        align-items: center;
    }
    
    .screen.tracker #step-title-bar {
        display: flex;
        flex-direction: column;
        height: 96px;
        background-color: #DEEEFF;
        width: 100%;
        margin-top: -16px;
        box-shadow: 0px 0px 27.6px 2.4px rgba(0, 56, 124, 0.4);
        padding-left: 5%;
        justify-content: center;
        align-items: flex-start;
        padding-top: 16px;
    }

    .screen.tracker #step-title-bar #duration {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    
    .screen.tracker #step-title-bar #title {
        font-family: Inter;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.63;
        letter-spacing: -0.32px;
        text-align: left;
        color: #0052ca;
    }
    
    .screen.tracker #step-title-bar #icon {
        width: 15px;
        height: 15px;
        margin-left: 0px;
        background-image: url('../../../assets/imgs/clock-icon.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    
    .screen.tracker #step-title-bar #timeframe {
        margin-left: 7px;
        font-family: Inter;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.14px;
        text-align: right;
        color: #06285e;
    }
    
    .screen.tracker #step-content-bar {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        box-shadow: unset;
        background-color: #fff;
        width: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column-reverse;
        z-index: 105;
    }
    
    .screen.tracker #step-content-bar #description {
        flex: 2;
        border-right: unset;
        margin-top: 30px;
        padding-left: unset;
        padding-right: unset;
    }
    
    .screen.tracker #step-content-bar #description .title {
        font-family: Inter;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.16px;
        text-align: left;
        color: #0052ca;
        margin-bottom: 24px;
        padding-left: 5%;
        padding-right: 5%;
    }
    
    .screen.tracker #step-content-bar #description .text {
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.16px;
        text-align: left;
        color: #6d6e71;
        margin-bottom: 48px;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        padding-bottom: 24px;
        box-shadow: 0px 11px 11px 0px rgba(0,82,202,0.2);
    }

    .screen.tracker #step-content-bar #description .text:last-child {
        box-shadow: none;
        padding-bottom: 12px;
        margin-bottom: 24px;
    }
    
    .screen.tracker #step-content-bar #video-content {
        flex: 1;
        padding-left: unset;
    }
    
    .screen.tracker #step-content-bar #video-content #thumbnail {
        width: 100%;
        height: 244.5px;
        background-image: url('../../../assets/imgs/video-thumb.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    
    .screen.tracker #step-content-bar #video-content #text {
        display: none;
    }
    
    .screen.tracker #next-step-bar {
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        background: unset;
        background-color: rgb(128,193,255,1);
        width: 100%;
        margin-top: unset;
        padding: 29px 5% 29px 5%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    
    .screen.tracker #next-step-bar #icon {
        width: 60px;
        height: 60px;
        background-image: url('../../../assets/imgs/what-is-next.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    
    .screen.tracker #next-step-bar #text {
        margin-left: 17px;
        display: flex;
        flex-direction: column;
    }
    
    .screen.tracker #next-step-bar #text span:first-child {
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #fff;
        margin-top: 5px;
    }
    
    .screen.tracker #next-step-bar #text span,
    .screen.tracker #next-step-bar #text span:last-child {
        margin-left: 5px;
        font-family: Inter;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: -0.18px;
        text-align: left;
        color: #06285e;
    }

    .video-dialog {
        width: 98%;
        max-width: unset;
    }

}