.footer {
    padding-top: 30px;
    background-color: #06285e;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.footer .links-container {
    display: flex;
}

.footer .links {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
}

.footer .links .link {
    cursor: pointer;
    white-space: nowrap;
}

.footer .links .divider {
    margin-left: 10px;
    margin-right: 10px;
}

.footer .review {
    background-image: url('../../../assets/imgs/write-review-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 427px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;
}

.footer .review .title {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}

.footer .review .sub-title {
    margin-top: 11px;
    font-family: Inter;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #80c0ff;
}

.footer .review .sub-title {
    font-weight: 600;
}