.screen.signup {
    background-color: #06285e;
}

.screen.signup #main {
    background-color: #06285e;
    display: flex;    
}

.screen.signup #main #left {
    background-color: #06285e;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 166px;
}

.screen.signup #main #left #logo {
    background-image: url('../../../assets/imgs/elevation-icon.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 63px;
    height: 52px;
}

.screen.signup #main #left #title {
    margin-top: 24px;
    font-family: SpaceGrotesk;
    font-size: 60px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #80c1ff;
}

.screen.signup #main #left #subtitle {
    margin-top: 24px;
    font-family: SpaceGrotesk;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: -1.3px;
    text-align: left;
    color: #fff;
}

.screen.signup #main #left #form {
    margin-top: 38px;
    width: 453px;
}

.screen.signup #main #left #form .field {
    margin-top: 7px;
    width: 100%;
    height: 39px;
}

.screen.signup #main #left #form .field .p-inputtext,
.screen.signup #main #left #form .field .p-password {
    width: 100%;
    height: 100%;
    background-color: #06285e;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #80c1ff;
    font-family: Inter;
    font-size: 20.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: -0.27px;
    text-align: left;
    color: #fff;
}

.screen.signup #main #left #form .field .p-password {
    border-bottom: none;
}

.screen.signup #main #left #form .field input::placeholder {
    color: #fff;
    opacity: 0.8;
}

.screen.signup #main #left #form .field.checkbox {
    margin-top: 15px;
    display: flex;
}

.screen.signup #main #left #form .field.checkbox label {
    margin-left: 5px;
    font-family: "Inter";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.2px;
    text-align: left;
    color: #80c1ff;
}

.screen.signup #main #left #form .field.checkbox .p-checkbox-box {
    background-color: #fff;
    border: #80c1ff;
}

.screen.signup #main #left #form .field.checkbox .p-checkbox-icon {
    color: #80c1ff;
}

.screen.signup #main #left #form .field.checkbox label a, 
.screen.signup #main #left #form .field.checkbox label a:visited {
    color: #80c1ff;
    font-weight: bold;
}

.screen.signup #main #left #form .button {
    margin-top: 26px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 453px;
    height: 56px;
    cursor: pointer;
}

.screen.signup #main #left #form #login-button {
    background-image: url('../../../assets/imgs/login-button.png');
}

.screen.signup #main #left #form #sign-up-button {
    background-image: url('../../../assets/imgs/sign-up-button.png');
}

.screen.signup #main #left #links {
    margin-top: 20px;
}

.screen.signup #main #left #links .link-item {
    margin-top: 4px;
    font-family: "Inter";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.2px;
    text-align: left;
    color: #80c1ff;
}

.screen.signup #main #left #links .link-item span {
    margin-right: 4px;
}

.screen.signup #main #left #links .link-item span.icon {
    color: #fff;
}

.screen.signup #main #left #links .link-item span.link {
    cursor: pointer;
    font-weight: bolder;
    text-decoration: underline;
}

.screen.signup #main #right {
    background-image: url('../../../assets/imgs/external-screen-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    width: 72%;
    height: 100%;
    background-color: #06285e;
}